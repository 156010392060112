@media print {
  * {
    font-size: 30px;
  }

  body {
    color: black;
  }

  th {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-collapse: collapse;
  }

  td.description,
  th.description {
    width: 300px;
    max-width: 300px;
  }

  td.quantity,
  th.quantity {
    width: 120px;
    max-width: 120px;
    word-break: break-all;
  }

  td.price,
  th.price {
    width: 140px;
    max-width: 140px;
    word-break: break-all;
  }

  .centered {
    text-align: center;
    align-content: center;
  }

  .ticket {
    width: 400px;
    max-width: 400px;
  }

  img {
    max-width: inherit;
    width: inherit;
  }

  .hidden-print,
  .hidden-print * {
    display: none !important;
  }
}
