// scrollable table body
table.sc-table {

  &>thead {
    width: 100%;
    display: table;
    width: 100%;
  }

  &>tbody {
    max-height: 500px;
    overflow: auto;
    display: block;

    &>tr {
      display: table;
      width: 100%;
    }
  }
}