.transactions {
  display: grid;
  grid-template-columns: repeat(6, minmax(auto, 1fr));
  grid-gap: 5px;
  padding-top: 5px;
  background-color: #2c3436;

  .card {
    margin: 0;
  }
}
@media (max-width: 767px) {
  .transactions {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 479px) {
  .transactions {
    grid-template-columns: repeat(1, 1fr);
  }
}
