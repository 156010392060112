.validate-form {
  color: red;
}

.search-for-products {
  width: 50vw;
  max-width: 50vw;

  .ais-HierarchicalMenu {
    ul:first-child {
      padding-left: 0;
    }

    li {
      list-style-type: none;
    }
  }

  .ais-HierarchicalMenu-item--selected > a > span {
    font-weight: bolder;
  }

  .ais-Pagination-list {
    text-align: center;
    margin-top: 20px;

    ul {
      text-align: center;
    }

    li {
      display: inline;
      margin: 10px;
    }

    .ais-Pagination-item--selected a {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  .modal-body {
    .row > .col-lg-8 {
      max-height: 550px;
      overflow-y: auto;
    }
  }
}

.toggle-wrapper {
  margin: 0 auto;
}

.toggle-wrapper label {
  padding: 0.3em;
  font-size: 17px;
  display: block;
}

.toggleContainer {
  position: relative;
  background: #ffffff;
  border: 1px solid #dde6e9;
  border-radius: 3px;
  border-collapse: collapse;
}

.toggleContainer .toggleOption {
  display: inline-block;
  position: relative;
  z-index: 1;
  text-align: center;
  height: 33px;
  line-height: 1.528571429;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  color: #807b77;
  font-size: 13px;
  font-weight: 400;
  padding: 0.375rem 0rem;
  vertical-align: middle;
  white-space: nowrap;
  // border: 1px solid #dde6e9;
  // border-collapse: collapse;
}

.toggleContainer .toggleOption.selected {
  color: #ffffff;
  cursor: initial;
}

.oms-toggle-buttons .toggle {
  position: absolute;
  height: 100%;
  bottom: 0;
  left: 0;
  transition: all 0.4s ease-in-out;
  background: #4b91ea;
  border-radius: 3px;
}

.toggleContainer .toggle.functions-toggle {
  background: #f875cc;
}

.toggleContainer .toggle.standard-toggle {
  background: #cea962;
}

.toggleContainer .toggle.sharedSpace-toggle {
  background: #ffae4b;
}

.toggleContainer .toggle.external-toggle {
  background: #ffae4b;
}

.toggleContainer .toggle.allocated-toggle {
  background: #cea962;
}

.toggleContainer .toggle.standBy-toggle {
  background: #8db3ff;
}

.toggleContainer .toggle.seated-toggle {
  background: darkorange;
}

.toggleContainer .toggle.vacated-toggle {
  background: #7f7f7f;
}

.grid-container-inner {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
}
.grid-item-2 {
  grid-column: span 2;
}

$smallColumn: minmax(150px, 1fr);
$mediumColumn: minmax(240px, 1fr);
$largeColumn: minmax(300px, 1fr);
$extraColumn: minmax(220px, 1fr);
$repeatedColumns: repeat(2, $smallColumn) $mediumColumn $largeColumn $extraColumn repeat(1, $smallColumn);

.grid-container {
  display: grid;
  //grid-template-columns: $repeatedColumns;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  box-sizing: border-box;
  padding: 0.3rem;
}

@media (max-width: 1326px) {
  .grid-container {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 1023px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 767px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 479px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
$standardColor: #cea962;

.btn-standard {
  @include button-variant($standardColor, darken($standardColor, 0), darken($standardColor, 0), lighten($standardColor, 0), lighten($standardColor, 0), darken($standardColor, 0));
  color: #fff !important;

  :active {
    color: #fff !important;
  }
}

.btn-linkedTable,
.btn-hiddenDockets {
  background-color: #f05050 !important;
  border-color: #f05050 !important;
}

.btn-outline-standard {
  @include button-outline-variant($standardColor);
}

$sharedSpaceColor: #ffae4b;

.btn-sharedSpace,
.btn-externalSpace {
  @include button-variant($sharedSpaceColor, darken($sharedSpaceColor, 0), darken($sharedSpaceColor, 0), lighten($sharedSpaceColor, 0), lighten($sharedSpaceColor, 0), darken($sharedSpaceColor, 0));
  color: #fff !important;

  :active {
    color: #fff !important;
  }
}

.btn-outline-sharedSpace,
.btn-outline-externalSpace {
  @include button-outline-variant($sharedSpaceColor);
}

$functionColor: #f875cc;

.btn-function {
  @include button-variant($functionColor, darken($functionColor, 0), darken($functionColor, 0), lighten($functionColor, 0), lighten($functionColor, 0), darken($functionColor, 0));
  color: #fff !important;

  :active {
    color: #fff !important;
  }
}

.topnavbar .navbar-header .brand-logo-collapsed {
  padding-top: 0px;
  padding-bottom: 0px;

  > img {
    margin-right: 10px;
  }
}

.topnavbar .navbar-header .navbar-brand {
  width: unset;
}

.navbar-brand .header-logo {
  display: inline;
  margin-right: 10px;

  &--lg {
    display: none;
  }

  @media (min-width: 500px) {
    &--sm {
      display: none;
    }

    &--lg {
      display: inline;
    }
  }
}

.line-through {
  text-decoration-line: line-through;
}

.menu-items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.bg-black {
  background: #000;
}
